<template>
<v-container>
  <v-row class="pa-3">
    <v-btn color="blue" @click="onUpload">
      上传文件
    </v-btn>
    <input type="file" ref="fileIntercom" style="display:none;" @change="insertFile">

    <span class="pa-3"></span>
    <v-btn color="blue" @click="onNewDir">
      新建文件夹
    </v-btn>
  </v-row>
  <v-row>
    项目文件
  </v-row>
  <v-row>
    <v-treeview activatable selection-type="independent" hoverable :active="active" :items="paths" item-children="dir_dirs" @update:active="getActiveValue">
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="!!item.dir_name" color="#1565c0">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else>
          {{ "mdi-file-document-outline" }}
        </v-icon>
      </template>

      <template v-slot:label="{ item, open }">
        <div v-if="renameChk.id!=item.id">
          {{ !!item.dir_name ? item.dir_name : item.file_name }}
        </div>
        <div v-if="renameChk.id==item.id" style="width:200px;">
          <v-text-field v-model="renameValue" :autofocus="true" v-on:keyup.enter="onChangeName(item)"></v-text-field>
        </div>
      </template>

      <template v-slot:append="{ item, open }">
        <div class="pl-5 tree-action" v-if="!item.dir_name">
          <span @click="onMove(item)">
            移动
          </span>
          <span @click="onDownload(item)">
            下载
          </span>
          <span @click="onRename(item)">
            重命名
          </span>
          <!-- <span @click="onShowBasicInfo(item)">
            查看原贴
          </span> -->
        </div>

        <div class="pl-5 tree-action " v-else>
          <span @click="onMove(item)">
            移动
          </span>
          <span @click="onRename(item)">
            重命名
          </span>
          <span @click="onDelete(item)">
            删除
          </span>
        </div>
      </template>
    </v-treeview>
  </v-row>
  <v-row> </v-row>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-treeview activatable hoverable :active="active" :items="paths" item-children="dir_dirs" @update:active="getActiveValue">
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!!item.dir_name" color="#1565c0">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon v-else>
            {{ "mdi-file-document-outline" }}
          </v-icon>
        </template>

        <template v-slot:label="{ item, open }">
            {{ !!item.dir_name ? item.dir_name : item.file_name }}
        </template>
      </v-treeview>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

import * as endPoint from "@/config/endpoints";

export default {
  name: "ItemFile",
  props: ["item"],
  components: {},
  data() {
    return {
      dialog: false,
      tab: null,
      paths: [],
      active: [],
      selId: null,
      renameChk: {},
      renameValue: '',
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  created() {},
  mounted() {
    this.initDir();
  },
  methods: {
    ...mapActions("directory", ["getDir", "addDir", "removeDir", "updateDir"]),
    ...mapActions('fileUpload', ['oneFileUpload', 'deleteFileUploadById', 'updateFile']),
    ...mapActions('app', ['setNotification']),
    onUpload() {
      if (!this.selId) {
        this.setNotification({
          status: 'warning',
          text: '请选择文件夹上传文件。'
        })
      } else {
        this.$refs.fileIntercom.click();
      }
    },
    insertFile(e) {
      this.file = e.target.files[0];
      if (this.file !== '') {
        var formData = new FormData();
        formData.append("files", this.file);
        this.oneFileUpload(formData).then((res) => {
          if (!!res && res.length > 0) {
            let newFile = {};
            newFile.file_id = res[0].file_id;
            newFile.file_parent = this.item.item_id;
            newFile.file_parent_type = 3;
            newFile.file_parent_dir = this.selId;
            this.updateFile(newFile).then(res => {
              if (res) {
                this.initDir();
              }
            })
            this.$refs.fileIntercom.value = '';
          }
        })
      }
    },
    onNewDir() {
      
      let newDir = {
        dir_name: "New Folder",
        dir_creator: this.authUser.id,
        dir_parent: this.item.item_id,
        dir_parent_type: 3,
        dir_parent_dir: this.selId,
      }

      this.addDir(newDir).then(res => {
        if (res) {
          this.initDir();
          
          res.id = res.dir_id;
          this.onRename(res);
        }

        // res.id = res.dir_id;
        // this.paths.unshift(res);
      })
      // this.paths.push(newDir);
    },
    getDirTyped(param) {
      if (param.dir_id) {
        param.id = param.dir_id;
      }
      param.dir_dirs = param.dir_dirs.map((dir) => {
        return this.getDirTyped(dir);
      });
      if (param.dir_files && param.dir_files.length > 0) {
        param.dir_files = param.dir_files.map(file => {
          file.id = param.dir_id + "_" + file.file_id;
          return file;
        });
      }
      param.dir_dirs = [...param.dir_dirs, ...param.dir_files];
      return param;
    },
    initDir() {
      this.getDir({
        dir_parent_type: 3,
        dir_parent: this.item.item_id,
        dir_parent_dir: 0,
      }).then((res) => {
        this.paths = res.map((dir) => {
          return this.getDirTyped(dir);
        });
        
      });
    },
    getActiveValue(value) {
      if (value.length > 0 && value[0] != this.renameChk.id) {
        this.renameChk = {};
      }
      this.selId = value.length > 0 ? (value[0] + "").split("_")[0] : null;
      
    },
    onMove(item) {
      
      this.dialog = true;
    },
    onDownload(item) {
      window.open(endPoint.FILE_DOWNLOAD + '/' + item.file_id, '_blank');
    },
    onRename(item) {
      this.renameChk = item;
      this.renameValue = item.dir_name ? item.dir_name : item.file_name;
    },
    onShowBasicInfo(item) {
      
    },
    onDelete(item) {
      
      this.removeDir({
        dir_id: item.dir_id
      }).then(res => {
        this.initDir();
      })
    },
    onChangeName(item) {
      if (item.dir_name) {
        this.updateDir({
          dir_id: item.dir_id,
          dir_name: this.renameValue
        }).then(res => {
          if (res) {
            this.initDir();
          }
        })
      } else {
        this.updateFile({
          file_id: item.file_id,
          file_name: this.renameValue
        }).then(res => {
          if (res) {
            this.initDir();
          }
        })
        // this.file_name = this.renameValue;
      }
      this.renameChk = {};
    },
  },
};
</script>

<style scoped>
.v-treeview {
  width: 100% !important;
}

.v-treeview-node .v-treeview-node--leaf,
.v-treeview-node__root:hover .tree-action {
  display: block;
}

.tree-action {
  display: none;
}

.tree-action:hover span:hover {
  color: #2196f3;
  cursor: pointer;
}
</style>
