import { render, staticRenderFns } from "./ItemFile.vue?vue&type=template&id=4b003dcd&scoped=true&"
import script from "./ItemFile.vue?vue&type=script&lang=js&"
export * from "./ItemFile.vue?vue&type=script&lang=js&"
import style0 from "./ItemFile.vue?vue&type=style&index=0&id=4b003dcd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b003dcd",
  null
  
)

export default component.exports